import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Route, Switch} from 'react-router-dom';

import './styles/globals.css';
import './styles/app.css';
// import 'antd/dist/antd.css';
import 'braft-editor/dist/index.css';
import './components/common/editor/editor.css';

import reportWebVitals from './reportWebVitals';

import {ConfigProvider} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import PATH from './components/common/pagePath';
import Home from './components/index';
import 'dayjs/locale/zh-cn';
import dayjs from "dayjs";
import SettingIndex from './components/user/UserSettingForm';
import PasswordIndex from './components/user/PasswordForm';
import UserIndex from './components/user/UserList';
import ProjectIndex, {ProjectReviewIndex,} from './components/project/ProjectList';
import YuelingIndex from './components/apply/ApplyList';
import UserDetailIndex from './components/user/UserDetail';
import InstitutionIndex from './components/institution/InstitutionList';
import InstitutionPermissions from './components/institution/InstitutionPermissions';
import InstitutionAuditList from './components/institution/audit/InstitutionAuditList'
import InstitutionAuditDetail from './components/institution/audit/InstitutionAuditDetail'
import QualificationIndex from './components/user/QulificationForm';
import InstitutionSettingIndex from './components/user/InstitutionSettingForm';
import MessageIndex from './components/message/MessageList';
import AdIndex from './components/ad/AdList';
import GongyiActivityIndex, {GongyiActivityReviewIndex,} from './components/gongyiActivity/GongyiActivityList';
import GongyiActivityDetailIndex from './components/gongyiActivity/GongyiActivityDetail';
import DonationActivityIndex, {DonationActivityReviewIndex,} from './components/donationActivity/DonationActivityList';
import DonationActivityDetailIndex from './components/donationActivity/DonationActivityDetail';
import ADetail from './components/apply/ApplyDetail';
import ProjectDetailIndex from './components/project/ProjectDetail';
import ProjectFrom from './components/project/ProjectForm';
import InstitutionDetailIndex from './components/institution/InstitutionDetail';
// 账号管理
import AccountList from './components/account/accountList';
// 账户激活
import Activate from './components/activate/indexForm';
// 公益活动库----类别
import Category from './components/ATL/category/categoryList';
import Atlproject from './components/ATL/project/projectList';
import Atltemplate from './components/ATL/template/templateList';
import TemplateDetail from './components/ATL/template/templateDetail';

// 公益活动库----活动维护
import SelectTemplate from './components/ATL/active/selectTemplate';
import TemplateDetailS from './components/ATL/active/templateDetail';
import Limits from './components/user/limits';
import LimitsEdit from './components/user/limitsEdit';

//公益活动-----报名管理
import GongyiActivityRecruit from './components/gongyiActivity/GongyiActivityRecruit';

// 志愿者管理
import VolunteerIndex from './components/volunteer/VolunteerList';
import VolunteerVerify from './components/volunteer/VolunteerVerify';
import VolunteerDetail from './components/volunteer/VolunteerDetail';
import NotFound from './components/404/NotFound';
// 商城
import Supplier from './components/goods/supplier';
import GoodsList from './components/goods/list';
import GoodsEdit from './components/goods/goodsEdit';
import Mall from './components/goods/mall';
import GoodsView from './components/goods/goodsView';
import OrderList from './components/goods/OrderList';
import OrderDetail from './components/goods/orderDetail';
// 发票
import InvoiceList from './components/invoice/invoiceList';
import InvoiceDetail from './components/invoice/invoiceDetail';

// demo
import DemoList from './components/demo/demoList';
import DemoDetail from './components/demo/demoDetail';

//
import OperationalActivityList from './components/operationalActivity/operationalActivityList';
import OperationalActivityDetail from './components/operationalActivity/operationalActivityDetail';
import OperationalActivityEdit from './components/operationalActivity/operationalActivityEdit';

import CooperateServiceUsersAddList from './components/cooperate/add/ServiceUserAddList';
// import CooperateServiceUsersAddEdit from './components/cooperate/add/ServiceUserAddEdit';
// import ServiceUserFinallyAuditPage from './components/cooperate/add/ServiceUserFinallyAudit';
// import ServiceUserPatientAuditPage from './components/cooperate/add/ServiceUserPatientAudit';
import ServiceUserFinancialList from './components/cooperate/financialaudit/ServiceUserFinancialList';
import DhHospitalList from './components/dhHospital/dhHospitalList';
import ServiceUserFinancialAudit from './components/cooperate/financialaudit/ServiceUserFinancialAudit';


// import ServiceUserHospitalList from './components/cooperate/hospitalaudit/ServiceUserHospitalList';
// import ServiceUserHospitalScreeningPage from './components/cooperate/hospitalaudit/ServiceUserHospitalScreening';
// import AierHospitalPostoperativeInfoPage from './components/cooperate/hospitalaudit/AierHospitalPostoperativeInfo';
import ServiceUserDetailPage from './components/cooperate/detail/ServiceUserDetail';
import RegisteredForm from './components/register/RegisteredForm';
import RegisteredSuccessPage from './components/register/RegisteredSuccess';

import ServiceUserRosterListPage from './components/cooperate/userroster/ServiceUserRosterList';
import MonthlyStatisticsListPage from './components/cooperate/monthlystatistice/MonthlyStatisticsList';
// import PublicizeVideoListPage from './components/cooperate/publicizevideo/PublicizeVideoList';
// import FileModalPage from './components/common/modal/FileModal';
import ServiceUserHospitalV2ListPage from './components/cooperate/hospitalauditv2/ServiceUserHospitalV2List';
import ServiceUserHospitalV2ScreeningPage from './components/cooperate/hospitalauditv2/ServiceUserHospitalV2Screening';
import AierHospitalV2PostoperativeInfoPage
    from './components/cooperate/hospitalauditv2/AierHospitalV2PostoperativeInfo';
import AierHospitalV2RefinePage from './components/cooperate/hospitalauditv2/AierHospitalV2Refine';
import EditPatientAccountPage from './components/cooperate/hospitalauditv2/EditPatientAccount';

import ReimbursedBatchListPage from './components/cooperate/reimbursedbatch/ReimbursedBatchList';
import ReimbursedBatchDetailListPage from './components/cooperate/reimbursedbatch/ReimbursedBatchDetailList';

import HospitalStatsIndexPage from './components/cooperate/hospitalstats/HospitalStatsIndex';

import HospitalRoleListPage from './components/cooperate/hospitalrole/HospitalRoleList';
// import EditHospitalRolePage from './components/cooperate/hospitalrole/EditHospitalRole';
import HospitalAppletsConfigListPage from './components/cooperate/hospitalappletsconfig/HospitalAppletsConfigList';

import HospitalAccountEditPage from './components/cooperate/hospitalaccount/HospitalAccountEdit';

import HospitalBillListPage from './components/cooperate/bill/hospitalbill/HospitalBillList';
import HospitalBillDetailPage from './components/cooperate/bill/hospitalbilldetail/HospitalBillDetail';
import HospitalBillMaterialsPage from './components/cooperate/bill/hospitalbill/HospitalBillMaterials';
import FinancialBillListPage from './components/cooperate/bill/financialbill/FinancialBillList';
import FinancialBillAuditPage from './components/cooperate/bill/financialbill/FinancialBillAudit';
import HospitalBillReceiptPage from './components/cooperate/bill/hospitalbill/HospitalBillReceipt';
import FinancialBillAuditReceiptPage from './components/cooperate/bill/financialbill/FinancialBillAuditReceipt';

import FinancialBillBatchListPage from './components/cooperate/bill/billbatch/FinancialBillBatchList';
import FinancialBillBatchDetailListPage from './components/cooperate/bill/billbatch/FinancialBillBatchDetailList';

import PatientApplicationListPage from "./components/dhHospital/patientApplication/DhPatientApplicationList";
import DhServiceUserDetailPage from "./components/dhHospital/patientDetail/ServiceUserDetail";
import DhPatientBookingPage from "./components/dhHospital/patientApplication/DhPatientBooking";
import DhHospitalPatientListPage from "./components/dhHospital/hospitalPatient/DhHospitalPatientList";
import HospitalPatientDetailPage from "./components/dhHospital/hospitalPatient/HospitalPatientDetail";
import DhHospitalUploadReportPage from "./components/dhHospital/hospitalPatient/DhHospitalUploadReport";


import HospitalDashboardPage from "./components/dhHospital/hospitalDashboard/HospitalDashboard";
import AddDhPatientApplicationPage from "./components/dhHospital/patientApplication/AddDhPatientApplication";
import EditDhPatientApplicationPage from "./components/dhHospital/patientApplication/EditDhPatientApplication";
import DhSalesmanListPage from "./components/dhHospital/salesman/DhSalesmanList";
import DhPhoneTaskListPage from "./components/dhHospital/phonetask/DhPhoneTaskList";
import DhPhoneTaskAllListPage from "./components/dhHospital/phonetask/DhPhoneTaskAllList";
import DhReturnVisitDisposePage from "./components/dhHospital/phonetask/DhReturnVisitDispose";
import DhReturnVisitDetailPage from "./components/dhHospital/phonetask/DhReturnVisitDetail";
import DhObligatePhoneDetailPage from "./components/dhHospital/phonetask/DhObligatePhoneDetail";

import EditDhPatientHospitalPage from "./components/dhHospital/hospitalPatient/EditDhPatientHospital";
import DhPhoneTaskDetailPage from "./components/dhHospital/phonetask/DhPhoneTaskDetail";
import DhServiceUserFeePage from "./components/dhHospital/hospitalPatient/DhServiceUserFee";
import DhFinanceAuditsListPage from "./components/dhHospital/financialaudits/DhFinanceAuditsList";
import DhFinancialAuditPage from "./components/dhHospital/financialaudits/DhFinancialAudit";
import DhReimbursedBatchListPage from "./components/dhHospital/reimbursedbatch/ReimbursedBatchList";
import DhReimbursedBatchDetailListPage from "./components/dhHospital/reimbursedbatch/ReimbursedBatchDetailList";
import DhHospitalGroupPatientListPage from "./components/dhHospital/hospitalPatient/DhHospitalGroupPatientList";

import TaxesListPage from "./components/taxes/TaxesList";
import TaxesDetailPage from "./components/taxes/TaxesDetail";

import TestDayJsListPage from "./components/testdayjs/TestDayJs";
import ServiceUserVoucherListPage from "./components/cooperate/financialaudit/ServiceUserVoucher";
import ExamPaperListPage from "./components/questions/paper/ExamPaperList";
import ExamQuesListPage from "./components/questions/ques/ExamQuesList";
import ExamQuesAddPage from "./components/questions/ques/ExamQuesAdd";
import ExamAnswerUserListPage from "./components/questions/answeruser/ExamAnswerUserList";
import ExamAnswerUserDetailPage from "./components/questions/answeruser/ExamAnswerUserDetail";
import ExamUserAnswerListPage from "./components/questions/useranswer/ExamUserAnswerList";
import ExamUserAnswerDetailPage from "./components/questions/useranswer/ExamUserAnswerDetail";
import ExamAnswerUserRankingPage from "./components/questions/ranking/ExamAnswerUserRanking";
import MergeUnitGroupPagePage from "./components/questions/ranking/MergeUnitGroupPage";
import ExamAnswerUpdateUnitListPage from "./components/questions/ranking/ExamAnswerUpdateUnitList";
import HospitalGroupListPage from "./components/cooperate/hospitalgroup/HospitalGroupList";


dayjs.locale('zh-cn');

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={zhCN}>
            {/* @ts-ignore */}
            <HashRouter>
                <Suspense fallback={<div>出错了</div>}>
                    <Switch>
                        <Route path={`${PATH.hospital_group.list}`} exact>
                            <HospitalGroupListPage/>
                        </Route>
                        <Route path={`${PATH.exam.examStatistics.log}`} exact>
                            <ExamAnswerUpdateUnitListPage/>
                        </Route>
                        <Route path={`${PATH.exam.examStatistics.merge_group}`} exact>
                            <MergeUnitGroupPagePage/>
                        </Route>
                        <Route path={`${PATH.exam.examStatistics.find_unit}`} exact>
                            <ExamAnswerUserRankingPage/>
                        </Route>
                        <Route path={`${PATH.exam.userAnswer.detail}`} exact>
                            <ExamUserAnswerDetailPage/>
                        </Route>
                        <Route path={`${PATH.exam.userAnswer.list}`} exact>
                            <ExamUserAnswerListPage/>
                        </Route>
                        <Route path={`${PATH.exam.answerUser.detail}`} exact>
                            <ExamAnswerUserDetailPage/>
                        </Route>
                        <Route path={`${PATH.exam.answerUser.list}`} exact>
                            <ExamAnswerUserListPage/>
                        </Route>
                        <Route path={`${PATH.exam.ques.add}`} exact>
                            <ExamQuesAddPage/>
                        </Route>
                        <Route path={`${PATH.exam.ques.list}`} exact>
                            <ExamQuesListPage/>
                        </Route>
                        <Route path={`${PATH.exam.paper.list}`} exact>
                            <ExamPaperListPage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersFinancialAudit.financialBatchVoucher}`} exact>
                            <ServiceUserVoucherListPage/>
                        </Route>
                        <Route path={`${PATH.test.testTime}`} exact>
                            <TestDayJsListPage/>
                        </Route>
                        <Route path={`${PATH.taxes.detail}`} exact>
                            <TaxesDetailPage/>
                        </Route>
                        <Route path={`${PATH.taxes.list}`} exact>
                            <TaxesListPage/>
                        </Route>
                        <Route path={`${PATH.dhHospitalPatient.group_list}`} exact>
                            <DhHospitalGroupPatientListPage/>
                        </Route>
                        <Route path={`${PATH.reimbursed_batch.detail_list}`} exact>
                            <DhReimbursedBatchDetailListPage/>
                        </Route>
                        <Route path={`${PATH.reimbursed_batch.list}`} exact>
                            <DhReimbursedBatchListPage/>
                        </Route>
                        <Route path={`${PATH.dhFinance.audit}`} exact>
                            <DhFinancialAuditPage/>
                        </Route>
                        <Route path={`${PATH.dhFinance.list}`} exact>
                            <DhFinanceAuditsListPage/>
                        </Route>
                        <Route path={`${PATH.dhHospitalPatient.fee}`} exact>
                            <DhServiceUserFeePage/>
                        </Route>
                        <Route path={`${PATH.dhPhoneTask.phone_task_detail}`} exact>
                            <DhPhoneTaskDetailPage/>
                        </Route>
                        <Route path={`${PATH.dhPhoneTask.obligate_detail}`} exact>
                            <DhObligatePhoneDetailPage/>
                        </Route>
                        <Route path={`${PATH.dhHospitalPatient.edit}`} exact>
                            <EditDhPatientHospitalPage/>
                        </Route>
                        <Route path={`${PATH.dhPhoneTask.return_visit_detail}`} exact>
                            <DhReturnVisitDetailPage/>
                        </Route>
                        <Route path={`${PATH.dhPhoneTask.return_visit}`} exact>
                            <DhReturnVisitDisposePage/>
                        </Route>
                        <Route path={`${PATH.dhPhoneTask.all_list}`} exact>
                            <DhPhoneTaskAllListPage/>
                        </Route>
                        <Route path={`${PATH.dhPhoneTask.list}`} exact>
                            <DhPhoneTaskListPage/>
                        </Route>
                        <Route path={`${PATH.dhSalesman.list}`} exact>
                            <DhSalesmanListPage/>
                        </Route>
                        <Route path={`${PATH.dhPatientApplication.edit}`} exact>
                            <EditDhPatientApplicationPage/>
                        </Route>
                        <Route path={`${PATH.dhPatientApplication.add}`} exact>
                            <AddDhPatientApplicationPage/>
                        </Route>
                        <Route path={`${PATH.dhDashboard.hospital_dashboard}`} exact>
                            <HospitalDashboardPage/>
                        </Route>
                        <Route path={`${PATH.dhHospitalPatient.report}`} exact>
                            <DhHospitalUploadReportPage/>
                        </Route>
                        <Route path={`${PATH.dhHospitalPatient.detail}`} exact>
                            <HospitalPatientDetailPage/>
                        </Route>
                        <Route path={`${PATH.dhHospitalPatient.list}`} exact>
                            <DhHospitalPatientListPage/>
                        </Route>
                        <Route path={`${PATH.dhPatientApplication.booking}`} exact>
                            <DhPatientBookingPage/>
                        </Route>
                        <Route path={`${PATH.dhPatientApplication.detail}`} exact>
                            <DhServiceUserDetailPage/>
                        </Route>
                        <Route path={`${PATH.dhPatientApplication.list}`} exact>
                            <PatientApplicationListPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill.audit_receipt}`} exact>
                            <FinancialBillAuditReceiptPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill.receipt}`} exact>
                            <HospitalBillReceiptPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill_batch.detail_list}`} exact>
                            <FinancialBillBatchDetailListPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill_batch.list}`} exact>
                            <FinancialBillBatchListPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill.financial_bill_audit}`} exact>
                            <FinancialBillAuditPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill.financial_bill_list}`} exact>
                            <FinancialBillListPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill.materials}`} exact>
                            <HospitalBillMaterialsPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill.detail}`} exact>
                            <HospitalBillDetailPage/>
                        </Route>
                        <Route path={`${PATH.service_user_bill.hos_query_page}`} exact>
                            <HospitalBillListPage/>
                        </Route>
                        <Route path={`${PATH.hospitalAccount.hospitalAccountEdit}`} exact>
                            <HospitalAccountEditPage/>
                        </Route>
                        <Route path={`${PATH.hospital_applets_config.list}`} exact>
                            <HospitalAppletsConfigListPage/>
                        </Route>
                        <Route path={PATH.dhHospital.dhHospitalList} exact>
                            <DhHospitalList/>
                        </Route>
                        {/*<Route path={`${PATH.aier_hospital.edit_aier_hospital}`} exact>*/}
                        {/*    <EditHospitalRolePage/>*/}
                        {/*</Route>*/}
                        <Route path={`${PATH.aier_hospital.list}`} exact>
                            <HospitalRoleListPage/>
                        </Route>
                        <Route path={`${PATH.hospitalStats.hospitalStatsIndex}`} exact>
                            <HospitalStatsIndexPage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersHospitalV2.editPatientAccount}`} exact>
                            <EditPatientAccountPage/>
                        </Route>
                        <Route path={`${PATH.reimbursedBatch.detailBatchList}`} exact>
                            <ReimbursedBatchDetailListPage/>
                        </Route>
                        <Route path={`${PATH.reimbursedBatch.batchList}`} exact>
                            <ReimbursedBatchListPage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersHospitalV2.refine}`} exact>
                            <AierHospitalV2RefinePage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2List}`} exact>
                            <ServiceUserHospitalV2ListPage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2List}`} exact>
                            <ServiceUserHospitalV2ListPage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2Screening}`} exact>
                            <ServiceUserHospitalV2ScreeningPage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2PostoperativeInfo}`} exact>
                            <AierHospitalV2PostoperativeInfoPage/>
                        </Route>
                        {/*<Route path={`${PATH.fileView.view}`} exact>*/}
                        {/*    <FileModalPage/>*/}
                        {/*</Route>*/}
                        <Route path={`${PATH.monthlyStatistics.monthlyStatisticsList}`} exact>
                            <MonthlyStatisticsListPage/>
                        </Route>
                        <Route path={`${PATH.cooperateServiceUsersRoster.serviceUserRosterList}`} exact>
                            <ServiceUserRosterListPage/>
                        </Route>
                        {/*<Route path={`${PATH.cooperateServiceUsers.serviceUserPatientAudit}`} exact>*/}
                        {/*    <ServiceUserPatientAuditPage/>*/}
                        {/*</Route>*/}
                        <Route path={`${PATH.registered.registerSuccess}`} exact>
                            <RegisteredSuccessPage/>
                        </Route>
                        {/*<Route path={`${PATH.cooperateServiceUsersHospitalAudit.hospitalAuditPostoperativeInfo}`} exact>*/}
                        {/*    <AierHospitalPostoperativeInfoPage/>*/}
                        {/*</Route>*/}
                        <Route path={`${PATH.cooperateServiceUsers.serviceUserDetail}`} exact>
                            <ServiceUserDetailPage/>
                        </Route>
                        {/*<Route path={`${PATH.cooperateServiceUsersHospitalAudit.hospitalAuditScreening}`} exact>*/}
                        {/*    <ServiceUserHospitalScreeningPage/>*/}
                        {/*</Route>*/}
                        <Route path={PATH.institution.reviewedList} exact>
                            <InstitutionAuditList/>
                        </Route>
                        <Route path={`${PATH.institution.reviewedDetail}/:id`} exact>
                            <InstitutionAuditDetail/>
                        </Route>
                        <Route path={PATH.login} exact>
                            <Home/>
                        </Route>
                        <Route path={PATH.alt.category} exact>
                            <Category/>
                        </Route>
                        <Route path={PATH.alt.atlproject} exact>
                            <Atlproject/>
                        </Route>
                        <Route path={PATH.alt.atltemplate} exact>
                            <Atltemplate/>
                        </Route>
                        <Route path={PATH.alt.selectTemplate} exact>
                            <SelectTemplate/>
                        </Route>

                        <Route path={`${PATH.alt.templatedetail}/:id/:detail`} exact>
                            <TemplateDetail/>
                        </Route>
                        <Route
                            path={`${PATH.alt.templatedetails}/:tmpid/:detail/:id`}
                            exact
                        >
                            <TemplateDetailS/>
                        </Route>
                        {/* 组织激活 */}
                        <Route path={PATH.activate} exact>
                            <Activate/>
                        </Route>
                        <Route path={PATH.account.list}>
                            <AccountList/>
                        </Route>
                        <Route path={PATH.volunteer.list}>
                            <VolunteerIndex/>
                        </Route>
                        <Route path={`${PATH.volunteer.detail}/:type/:id/:userid`}>
                            <VolunteerDetail/>
                        </Route>
                        <Route path={PATH.volunteer.verify}>
                            <VolunteerVerify/>
                        </Route>
                        <Route path={PATH.project.list} exact>
                            <ProjectIndex/>
                        </Route>
                        <Route path={`${PATH.project.detail}/:id`} exact>
                            <ProjectDetailIndex/>
                        </Route>
                        <Route path={PATH.project.edit} exact>
                            <ProjectFrom/>
                        </Route>
                        <Route path={PATH.project.review} exact>
                            <ProjectReviewIndex/>
                        </Route>
                        <Route path={PATH.gongyiActivity.list} exact>
                            <GongyiActivityIndex/>
                        </Route>
                        <Route path={PATH.gongyiActivity.review} exact>
                            <GongyiActivityReviewIndex/>
                        </Route>
                        <Route path={`${PATH.gongyiActivity.detail}/:id`} exact>
                            <GongyiActivityDetailIndex/>
                        </Route>
                        <Route path={PATH.gongyiActivity.recruit} exact>
                            <GongyiActivityRecruit/>
                        </Route>
                        <Route path={PATH.donationActivity.list} exact>
                            <DonationActivityIndex/>
                        </Route>
                        <Route path={`${PATH.donationActivity.detail}/:id`} exact>
                            <DonationActivityDetailIndex/>
                        </Route>
                        <Route path={PATH.donationActivity.review} exact>
                            <DonationActivityReviewIndex/>
                        </Route>
                        <Route path={PATH.yueling.list} exact>
                            <YuelingIndex/>
                        </Route>
                        <Route path={`${PATH.yueling.detail}/:id`}>
                            <ADetail/>
                        </Route>
                        <Route path={PATH.institution.list} exact>
                            <InstitutionIndex/>
                        </Route>
                        <Route path={`${PATH.institution.index}/:id`} exact>
                            <InstitutionDetailIndex/>
                        </Route>
                        <Route path={PATH.user.info}>
                            <SettingIndex/>
                        </Route>
                        <Route path={PATH.user.institution}>
                            <InstitutionSettingIndex/>
                        </Route>
                        <Route path={PATH.user.list} exact>
                            <UserIndex/>
                        </Route>
                        <Route path={PATH.user.qualification}>
                            <QualificationIndex/>
                        </Route>
                        <Route path={`${PATH.user.detail}/:id`}>
                            <UserDetailIndex/>
                        </Route>
                        <Route path={PATH.user.password}>
                            <PasswordIndex/>
                        </Route>
                        <Route path={PATH.user.limits}>
                            <Limits/>
                        </Route>
                        <Route path={PATH.user.limitsedit}>
                            <LimitsEdit/>
                        </Route>
                        <Route path={PATH.message.list} exact>
                            <MessageIndex/>
                        </Route>
                        <Route path={PATH.ad.list} exact>
                            <AdIndex/>
                        </Route>
                        <Route path={PATH.goods.supplier} exact>
                            <Supplier/>
                        </Route>
                        <Route path={PATH.goods.list} exact>
                            <GoodsList/>
                        </Route>
                        <Route path={PATH.goods.edit} exact>
                            <GoodsEdit/>
                        </Route>
                        <Route path={PATH.goods.mall} exact>
                            <Mall/>
                        </Route>
                        <Route path={PATH.goods.view} exact>
                            <GoodsView/>
                        </Route>
                        <Route path={PATH.goods.orderlist} exact>
                            <OrderList/>
                        </Route>
                        <Route path={PATH.goods.orderdetail} exact>
                            <OrderDetail/>
                        </Route>
                        <Route path={PATH.invoice.list} exact>
                            <InvoiceList/>
                        </Route>
                        <Route path={PATH.invoice.detail} exact>
                            <InvoiceDetail/>
                        </Route>
                        <Route path={PATH.demo.list} exact>
                            <DemoList/>
                        </Route>
                        <Route path={PATH.demo.detail} exact>
                            <DemoDetail/>
                        </Route>
                        <Route path={PATH.operationalActivity.operationalActivityList} exact>
                            <OperationalActivityList/>
                        </Route>
                        <Route path={PATH.operationalActivity.operationalActivityDetail} exact>
                            <OperationalActivityDetail/>
                        </Route>
                        <Route path={PATH.operationalActivity.operationalActivityEdit} exact>
                            <OperationalActivityEdit/>
                        </Route>
                        <Route path={PATH.institution.institutionPermissions} exact>
                            <InstitutionPermissions/>
                        </Route>
                        <Route path={PATH.cooperateServiceUsers.cooperateServiceUsersAddList} exact>
                            <CooperateServiceUsersAddList/>
                        </Route>
                        {/*<Route path={PATH.cooperateServiceUsers.cooperateServiceUsersAddEdit} exact>*/}
                        {/*    <CooperateServiceUsersAddEdit/>*/}
                        {/*</Route>*/}
                        {/*<Route path={PATH.cooperateServiceUsersHospitalAudit.hospitalAuditList} exact>*/}
                        {/*    <ServiceUserHospitalList/>*/}
                        {/*</Route>*/}
                        <Route path={PATH.cooperateServiceUsersFinancialAudit.financialAuditList} exact>
                            <ServiceUserFinancialList/>
                        </Route>
                        <Route path={PATH.cooperateServiceUsersFinancialAudit.financialAuditAudit} exact>
                            <ServiceUserFinancialAudit/>
                        </Route>
                        <Route path={PATH.registered.doRegistered} exact>
                            <RegisteredForm/>
                        </Route>

                        <Route path="*">
                            <NotFound/>
                        </Route>
                    </Switch>
                </Suspense>
            </HashRouter>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
