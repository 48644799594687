import PATH from "../pagePath";


type configType = {
    url: string | ''
    selectedKeys: string | ''
    openKeys: string | ''
}

const AIER_OPHTHALMOLOGY = 'aierOphthalmology';
const DENTAL_HOSPITALS = "ORAL_GUARD";

const PAGE_KEY_MAP: Map<string, configType> = new Map();
PAGE_KEY_MAP.set(PATH.cooperateServiceUsers.cooperateServiceUsersAddList, {
    url: PATH.cooperateServiceUsers.cooperateServiceUsersAddList,
    selectedKeys: "ServiceUserAdd",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2List, {
    url: PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2List,
    selectedKeys: "hospitalAuditList",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.cooperateServiceUsersFinancialAudit.financialAuditList, {
    url: PATH.cooperateServiceUsersFinancialAudit.financialAuditList,
    selectedKeys: "financialAuditList",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.service_user_bill.financial_bill_list, {
    url: PATH.service_user_bill.financial_bill_list,
    selectedKeys: "FinancialBillList",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.monthlyStatistics.monthlyStatisticsList, {
    url: PATH.monthlyStatistics.monthlyStatisticsList,
    selectedKeys: "ServiceUserRoster",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.hospitalStats.hospitalStatsIndex, {
    url: PATH.hospitalStats.hospitalStatsIndex,
    selectedKeys: "hospitalStatsIndex",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.aier_hospital.list, {
    url: PATH.aier_hospital.list,
    selectedKeys: "hospitalRole",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.hospital_applets_config.list, {
    url: PATH.hospital_applets_config.list,
    selectedKeys: "HospitalAppletsConfig",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.hospitalAccount.hospitalAccountEdit, {
    url: PATH.hospitalAccount.hospitalAccountEdit,
    selectedKeys: "HospitalAccountEdit",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.service_user_bill.hos_query_page, {
    url: PATH.service_user_bill.hos_query_page,
    selectedKeys: "HospitalBillList",
    openKeys: AIER_OPHTHALMOLOGY
});
PAGE_KEY_MAP.set(PATH.dhHospital.dhHospitalList, {
    url: PATH.dhHospital.dhHospitalList,
    selectedKeys: "dhHospital",
    openKeys: AIER_OPHTHALMOLOGY
});

PAGE_KEY_MAP.set(PATH.dhPatientApplication.list, {
    url: PATH.dhPatientApplication.list,
    selectedKeys: "DH_APPLICATION",
    openKeys: DENTAL_HOSPITALS
});

PAGE_KEY_MAP.set(PATH.dhPatientApplication.detail, {
    url: PATH.dhPatientApplication.detail,
    selectedKeys: "DH_APPLICATION",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhPatientApplication.booking, {
    url: PATH.dhPatientApplication.booking,
    selectedKeys: "DH_APPLICATION",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhHospitalPatient.list, {
    url: PATH.dhHospitalPatient.list,
    selectedKeys: "DH_H_BOOKING",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhPatientApplication.add, {
    url: PATH.dhPatientApplication.add,
    selectedKeys: "DH_APPLICATION",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhPatientApplication.edit, {
    url: PATH.dhPatientApplication.edit,
    selectedKeys: "DH_APPLICATION",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhPhoneTask.list, {
    url: PATH.dhPhoneTask.list,
    selectedKeys: "DH_PHONE_TASK_LIST",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhPhoneTask.all_list, {
    url: PATH.dhPhoneTask.all_list,
    selectedKeys: "DH_PHONE_TASK_ALL_LIST",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhPhoneTask.return_visit, {
    url: PATH.dhPhoneTask.return_visit,
    selectedKeys: "DH_PHONE_TASK_LIST",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhHospitalPatient.edit, {
    url: PATH.dhHospitalPatient.edit,
    selectedKeys: "DH_H_BOOKING",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhFinance.list, {
    url: PATH.dhFinance.list,
    selectedKeys: "DH_FINANCE_AUDITS",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.dhHospitalPatient.group_list, {
    url: PATH.dhHospitalPatient.group_list,
    selectedKeys: "DH_H_GROUP_BOOKING",
    openKeys: DENTAL_HOSPITALS
});
PAGE_KEY_MAP.set(PATH.taxes.list, {
    url: PATH.taxes.list,
    selectedKeys: "TAXES_LIST",
    openKeys: ''
});
PAGE_KEY_MAP.set(PATH.taxes.detail, {
    url: PATH.taxes.detail,
    selectedKeys: "TAXES_LIST",
    openKeys: ''
});
PAGE_KEY_MAP.set(PATH.exam.paper.list, {
    url: PATH.exam.paper.list,
    selectedKeys: "EXAM_PAPER_LIST",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.ques.list, {
    url: PATH.exam.ques.list,
    selectedKeys: "EXAM_QUES_LIST",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.ques.add, {
    url: PATH.exam.ques.add,
    selectedKeys: "EXAM_QUES_LIST",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.answerUser.list, {
    url: PATH.exam.answerUser.list,
    selectedKeys: "AnswerUser",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.answerUser.detail, {
    url: PATH.exam.answerUser.detail,
    selectedKeys: "AnswerUser",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.userAnswer.list, {
    url: PATH.exam.userAnswer.list,
    selectedKeys: "UserAnswer",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.userAnswer.detail, {
    url: PATH.exam.userAnswer.detail,
    selectedKeys: "UserAnswer",
    openKeys: 'EXAM'
});

PAGE_KEY_MAP.set(PATH.exam.examStatistics.find_unit, {
    url: PATH.exam.examStatistics.find_unit,
    selectedKeys: "ExamStatistics",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.examStatistics.merge_group, {
    url: PATH.exam.examStatistics.merge_group,
    selectedKeys: "ExamStatistics",
    openKeys: 'EXAM'
});
PAGE_KEY_MAP.set(PATH.exam.examStatistics.log, {
    url: PATH.exam.examStatistics.log,
    selectedKeys: "ExamStatistics",
    openKeys: 'EXAM'
});


export default PAGE_KEY_MAP;
