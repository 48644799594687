import React, {useEffect, useState} from 'react';

import {Link, useHistory, withRouter} from 'react-router-dom';
import {
    AccountBookOutlined,
    ApartmentOutlined,
    AppstoreAddOutlined,
    AppstoreOutlined,
    AreaChartOutlined,
    BarsOutlined,
    ClearOutlined,
    ClusterOutlined,
    CodeSandboxOutlined,
    ContainerOutlined,
    DollarOutlined,
    DownOutlined,
    FieldTimeOutlined,
    FileDoneOutlined,
    FileProtectOutlined,
    HeartOutlined,
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    MessageOutlined,
    PayCircleOutlined,
    SolutionOutlined,
    TrophyOutlined,
    UndoOutlined,
    UpOutlined,
    UserAddOutlined,
    UsergroupAddOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Col, Dropdown, Layout, Menu, Row, Space} from 'antd';

import Fetch from './components/common/FetchIt';
import API from './components/common/api';

// import PATH from './components/common/pagePath';
import {getPath} from './components/user/UserSider';
import styles from "./components/index.module.css";
import {BackOrg} from "./components/common/listpage/SessionStorageUtil";
import AppTest from "./components/common/tencent/TcccSdkTest";
import {Upgrade} from "./components/common/Upgrade";

const {Header, Sider, Content} = Layout;
const SubMenu = Menu.SubMenu;
// type AppProps = {
//   selectedKeys: string[];
//   openKeys?: string;
//   children: ReactNode;
// };

const restIcon = function (key: any) {
    const bojIcon = {
        CodeSandboxOutlined: <CodeSandboxOutlined/>,
        HeartOutlined: <HeartOutlined/>,
        DollarOutlined: <DollarOutlined/>,
        FileDoneOutlined: <FileDoneOutlined/>,
        UsergroupAddOutlined: <UsergroupAddOutlined/>,
        LogoutOutlined: <LogoutOutlined/>,
        MenuFoldOutlined: <MenuFoldOutlined/>,
        MenuUnfoldOutlined: <MenuUnfoldOutlined/>,
        MessageOutlined: <MessageOutlined/>,
        UserOutlined: <UserOutlined/>,
        ClearOutlined: <ClearOutlined/>,
        AppstoreOutlined: <AppstoreOutlined/>,
        AppstoreAddOutlined: <AppstoreAddOutlined/>,
        BarsOutlined: <BarsOutlined/>,
        ApartmentOutlined: <ApartmentOutlined/>,
        UserAddOutlined: <UserAddOutlined/>,
        AreaChartOutlined: <AreaChartOutlined/>,
        FieldTimeOutlined: <FieldTimeOutlined/>,
        PayCircleOutlined: <PayCircleOutlined/>,
        ClusterOutlined: <ClusterOutlined/>,
        AccountBookOutlined: <AccountBookOutlined/>,
        ContainerOutlined: <ContainerOutlined/>,
        FileProtectOutlined: <FileProtectOutlined/>,
        SolutionOutlined: <SolutionOutlined/>,
        TrophyOutlined: <TrophyOutlined/>
    };
    // @ts-ignore
    return bojIcon[key] ? bojIcon[key] : <HeartOutlined/>;
};

function App(props: any) {


    const [collapsed, setCollapsed] = useState(false);
    const [isVisibleChange, setVisibleChange] = useState(false);

    useEffect(() => {
        Upgrade();
    }, []);

    function toggle() {
        setCollapsed(!collapsed);
    }

    let user = {username: '', roleId: -1};
    let orgName = '';
    if (sessionStorage.user) {
        user = JSON.parse(sessionStorage.user);
        orgName = user.username;
    }
    if (sessionStorage.orgName) {
        orgName = sessionStorage.orgName;
    }

    function logout(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        sessionStorage.clear();
        window.location.href = '/';
    }

    // 返回组织
    function goback() {
        // exitOrg;
        Fetch.get(API.institution.exitOrg).then((data) => {
            sessionStorage.token = data.accessToken;
            // sessionStorage.category = data.category;
            sessionStorage.orgName = data.orgName;
            // 是否可以返回
            sessionStorage.removeItem('goback');
            sessionStorage.buttons = JSON.stringify({
                buttons: data.buttons,
            });
            sessionStorage.treevos = JSON.stringify({
                arr: data.treeVos,
            });
            BackOrg()
            window.location.href = '/#/institution';
        });
    }

    const menu = (
        <Menu>
            {sessionStorage.getItem('goback') === '1' ? (
                <Menu.Item key="0" icon={<UndoOutlined/>}>
                    <div onClick={goback}>返回总管理后台</div>
                </Menu.Item>
            ) : (
                <Menu.Item key="0" icon={<UserOutlined/>}>
                    {getPath('个人中心')}
                </Menu.Item>
            )}
            <Menu.Item key="1" icon={<LogoutOutlined/>}>
                <a target="_blank" rel="noopener noreferrer" onClick={logout}>
                    退出登录
                </a>
            </Menu.Item>
        </Menu>
    );
    const history = useHistory();
    // const location = window.location;
    // let urls = props.location.pathname;

    // 配置页面权限
    // useEffect(() => {
    //   let urlArr = sessionStorage.getItem('treevos');
    //   let arr = new Array();
    //   if (urlArr) {
    //     urlArr = JSON.parse(urlArr);

    //     // @ts-ignore
    //     arr = urlArr.arr;
    //   }

    //   history.listen((routerD: any) => {
    //     // 判断是否有权限
    //     if (routerD.pathname !== '/') {
    //       // window.location.href = '/#/404';
    //       let flag = false;
    //       arr.map((item: any) => {
    //         if (item.routerUrl === '') {
    //           item.child.map((child: any) => {
    //             if (child.routerUrl === routerD.pathname) {
    //               flag = true;
    //             }
    //           });
    //         } else if (item.routerUrl === routerD.pathname) {
    //           flag = true;
    //         }
    //       });
    //       if (!flag) {
    //         window.location.href = '/#/404';
    //       }
    //     }
    //   });
    // }, [history]);
    function visibleChange(e: any) {
        setVisibleChange(e)
    }

    return (
        <Layout style={{minHeight: '100vh'}}>

            <Sider trigger={null} collapsible collapsed={collapsed} theme="light">
                <div className={collapsed ? 'logo logo-center' : 'logo'}>
                    {/*{collapsed ? '乐龄' : '乐龄志愿管理后台'}*/}
                    <img className={styles.menuLogoImg}
                         src={"https://appcdn.lelingzhiyuan.com//static/share/logo.png"}/>
                    乐龄志愿组织中心
                </div>
                {/* 侧边栏 */}
                {getLeftMenu(props)}
            </Sider>
            <Layout className="site-layout">
                <Header className="site-layout-background" style={{padding: 0}}>
                    <Row>
                        {/*<Col span={2}>*/}
                        {/*    {React.createElement(*/}
                        {/*        collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,*/}
                        {/*        {*/}
                        {/*            className: 'trigger',*/}
                        {/*            onClick: toggle,*/}
                        {/*        }*/}
                        {/*    )}*/}
                        {/*</Col>*/}
                        <Col style={{textAlign: 'right', paddingRight: '16px', width: '100%'}}>
                            <Dropdown overlay={menu} onVisibleChange={visibleChange}>
                                <a
                                    className="ant-dropdown-link"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <Space>
                                        {orgName}
                                        {isVisibleChange ? (<UpOutlined/>) : (<DownOutlined/>)}
                                    </Space>
                                </a>
                            </Dropdown>
                        </Col>
                    </Row>
                    <AppTest></AppTest>
                </Header>
                <Content style={{margin: '0 16px'}}>{props.children}</Content>
                {/* <Footer style={{ textAlign: 'center' }}>
          代码旋律 ©2021 Created by Shannon
        </Footer> */}
            </Layout>
        </Layout>
    );
}

// 获取侧边栏
function getLeftMenu(props: any) {
    let urlArr = sessionStorage.getItem('treevos');
    let arr = [];
    if (urlArr) {
        urlArr = JSON.parse(urlArr);

        // @ts-ignore
        arr = urlArr.arr;
    }

    return (
        <Menu
            theme="light"
            mode="inline"
            defaultOpenKeys={[props.openKeys]}
            defaultSelectedKeys={props.selectedKeys}
        >
            {
                <>
                    {/*<Menu.Item key="HospitalBillList" icon={<UserOutlined/>}>*/}
                    {/*    <Link to="/serviceUserBill/hos_query_page">医院的账单</Link>*/}
                    {/*</Menu.Item>*/}
                    {/*<Menu.Item key="hospitalGroup" icon={<UserOutlined/>}>*/}
                    {/*    <Link to="/hospital_group/list">医院组</Link>*/}
                    {/*</Menu.Item>*/}
                </>
            }

            {arr.map((item: any) => {
                if (item.routerUrl != '') {
                    return (
                        // @ts-ignore
                        <Menu.Item key={item.menuKey} icon={restIcon(item.icon)}>
                            <Link to={item.routerUrl}>{item.name}</Link>
                        </Menu.Item>
                    );
                } else {
                    return (
                        <SubMenu
                            key={item.menuKey}
                            icon={restIcon(item.icon)}
                            title={
                                <span>
                  <span>{item.name}</span>
                </span>
                            }
                        >
                            {item.child.map((child: any) => {
                                return (
                                    // @ts-ignore
                                    <Menu.Item key={child.menuKey} icon={restIcon(child.icon)}>
                                        <Link to={child.routerUrl}>{child.name}</Link>
                                    </Menu.Item>
                                );
                            })}
                        </SubMenu>
                    );
                }
            })}
        </Menu>
    );
}

export default withRouter(App);
