import PATH from "../pagePath";

export type BreadcrumbType = {
    url: string | ''
    name: string | ''
    root: boolean | true
}
const BREADCRUMB_INFO_CONFIG: Map<string, BreadcrumbType> = new Map();
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsers.cooperateServiceUsersAddList, {
    url: PATH.cooperateServiceUsers.cooperateServiceUsersAddList,
    name: "申请表",
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2List, {
    url: PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2List,
    name: '患者名单',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersFinancialAudit.financialAuditList, {
    url: PATH.cooperateServiceUsersFinancialAudit.financialAuditList,
    name: '报销名单Ⅰ',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill.financial_bill_list, {
    url: PATH.service_user_bill.financial_bill_list,
    name: '报销名单Ⅱ',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.monthlyStatistics.monthlyStatisticsList, {
    url: PATH.monthlyStatistics.monthlyStatisticsList,
    name: '花名册',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.hospitalStats.hospitalStatsIndex, {
    url: PATH.hospitalStats.hospitalStatsIndex,
    name: '数据看板',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.aier_hospital.list, {url: PATH.aier_hospital.list, name: '医院管理', root: true});
BREADCRUMB_INFO_CONFIG.set(PATH.hospital_applets_config.list, {
    url: PATH.hospital_applets_config.list,
    name: '小程序医院显示',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.hospitalAccount.hospitalAccountEdit, {
    url: PATH.hospitalAccount.hospitalAccountEdit,
    name: '银行账户',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill.hos_query_page, {
    url: PATH.service_user_bill.hos_query_page,
    name: '月度账单',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsers.serviceUserDetail, {
    url: PATH.cooperateServiceUsers.serviceUserDetail,
    name: '患者详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2Screening, {
    url: PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2Screening,
    name: '检查登记',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersHospitalV2.editPatientAccount, {
    url: PATH.cooperateServiceUsersHospitalV2.editPatientAccount,
    name: '编辑患者收款信息',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2PostoperativeInfo, {
    url: PATH.cooperateServiceUsersHospitalV2.hospitalAuditV2PostoperativeInfo,
    name: '检查审核',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.reimbursedBatch.batchList, {
    url: PATH.reimbursedBatch.batchList,
    name: '报销导出列表',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.reimbursedBatch.detailBatchList, {
    url: PATH.reimbursedBatch.detailBatchList,
    name: '报销导出详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill_batch.list, {
    url: PATH.service_user_bill_batch.list,
    name: '导出记录',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill_batch.detail_list, {
    url: PATH.service_user_bill_batch.detail_list,
    name: '批次详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersRoster.serviceUserRosterList, {
    url: PATH.cooperateServiceUsersRoster.serviceUserRosterList,
    name: '花名册明细',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersFinancialAudit.financialAuditAudit, {
    url: PATH.cooperateServiceUsersFinancialAudit.financialAuditAudit,
    name: '报销',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill.detail, {
    url: PATH.service_user_bill.detail,
    name: '账单详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill.materials, {
    url: PATH.service_user_bill.materials,
    name: '上传盖章账单',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill.financial_bill_audit, {
    url: PATH.service_user_bill.financial_bill_audit,
    name: '账单审核',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill.receipt, {
    url: PATH.service_user_bill.receipt,
    name: '上传收据',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.service_user_bill.audit_receipt, {
    url: PATH.service_user_bill.audit_receipt,
    name: '审核收据',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersHospitalV2.refine, {
    url: PATH.cooperateServiceUsersHospitalV2.refine,
    name: '完善信息',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhHospital.dhHospitalList, {
    url: PATH.dhHospital.dhHospitalList,
    name: '牙科数据',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPatientApplication.list, {
    url: PATH.dhPatientApplication.list,
    name: '申请表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPatientApplication.detail, {
    url: PATH.dhPatientApplication.detail,
    name: '患者详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPatientApplication.booking, {
    url: PATH.dhPatientApplication.booking,
    name: '预约医院',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhHospitalPatient.list, {
    url: PATH.dhHospitalPatient.list,
    name: '患者列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhHospitalPatient.detail, {
    url: PATH.dhHospitalPatient.detail,
    name: '患者详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhHospitalPatient.report, {
    url: PATH.dhHospitalPatient.report,
    name: '义诊报告',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhDashboard.hospital_dashboard, {
    url: PATH.dhDashboard.hospital_dashboard,
    name: '预约看板',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPatientApplication.add, {
    url: PATH.dhPatientApplication.add,
    name: '添加患者',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPatientApplication.edit, {
    url: PATH.dhPatientApplication.edit,
    name: '修改患者',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPhoneTask.list, {
    url: PATH.dhPhoneTask.list,
    name: '任务列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPhoneTask.all_list, {
    url: PATH.dhPhoneTask.all_list,
    name: '任务列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPhoneTask.return_visit, {
    url: PATH.dhPhoneTask.return_visit,
    name: '患者回访',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhHospitalPatient.edit, {
    url: PATH.dhHospitalPatient.edit,
    name: '编辑患者',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPhoneTask.return_visit_detail, {
    url: PATH.dhPhoneTask.return_visit_detail,
    name: '处理详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPhoneTask.obligate_detail, {
    url: PATH.dhPhoneTask.obligate_detail,
    name: '处理详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhPhoneTask.phone_task_detail, {
    url: PATH.dhPhoneTask.phone_task_detail,
    name: '任务详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhHospitalPatient.fee, {
    url: PATH.dhHospitalPatient.fee,
    name: '填写费用信息',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhFinance.list, {
    url: PATH.dhFinance.list,
    name: '报销名单',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhFinance.audit, {
    url: PATH.dhFinance.audit,
    name: '报销审核',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.reimbursed_batch.list, {
    url: PATH.reimbursed_batch.list,
    name: '报销导出列表',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.reimbursed_batch.detail_list, {
    url: PATH.reimbursed_batch.detail_list,
    name: '报销导出详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.dhHospitalPatient.group_list, {
    url: PATH.dhHospitalPatient.group_list,
    name: '全院患者名单',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.taxes.list, {
    url: PATH.taxes.list,
    name: '报税计划',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.taxes.detail, {
    url: PATH.taxes.detail,
    name: '报税详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.cooperateServiceUsersFinancialAudit.financialBatchVoucher, {
    url: PATH.taxes.detail,
    name: '批量上传凭证',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.paper.list, {
    url: PATH.exam.paper.list,
    name: '试卷列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.ques.list, {
    url: PATH.exam.ques.list,
    name: '试题列表',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.ques.add, {
    url: PATH.exam.ques.add,
    name: '添加试题',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.answerUser.list, {
    url: PATH.exam.answerUser.list,
    name: '参与列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.answerUser.detail, {
    url: PATH.exam.answerUser.detail,
    name: '用户详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.userAnswer.list, {
    url: PATH.exam.userAnswer.list,
    name: '答题列表',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.userAnswer.detail, {
    url: PATH.exam.userAnswer.detail,
    name: '答题详情',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.examStatistics.find_unit, {
    url: PATH.exam.examStatistics.find_unit,
    name: '答题排行',
    root: true
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.examStatistics.merge_group, {
    url: PATH.exam.examStatistics.merge_group,
    name: '单位组',
    root: false
});
BREADCRUMB_INFO_CONFIG.set(PATH.exam.examStatistics.log, {
    url: PATH.exam.examStatistics.log,
    name: '合并记录',
    root: false
});

export default BREADCRUMB_INFO_CONFIG;
